// Form field error states
export const formFieldErrorStates = function () {
  for (const field of document.querySelectorAll("[data-error-until]")) {
    for (const eventType of (
      field.getAttribute("data-error-until") || ""
    ).split(/\s+/)) {
      field.addEventListener(eventType, () => {
        for (const errorElement of field.querySelectorAll("[data-error]")) {
          errorElement.remove();
        }
      });
    }
  }
};
