import { createPopper } from "@popperjs/core";

export const handleTooltipEvents = function (attrName, handler) {
  const selector = `[${attrName.replace(/:/g, "\\:")}]`;
  for (const eventSource of document.querySelectorAll(selector)) {
    for (const eventType of (eventSource.getAttribute(attrName) || "").split(
      /\s+/,
    )) {
      eventSource.addEventListener(eventType, handler);
      // TODO: This is dirty and leaky way of making sure error tooltips are
      // displayed on `autofocus`ed field.
      if (eventType === "focus" && document.activeElement === eventSource)
        handler();
    }
  }
};

export const createToolTips = function () {
  for (const tooltipAnchor of document.querySelectorAll(
    "[data-tooltip-anchor]",
  )) {
    const tooltipId = tooltipAnchor.getAttribute("data-tooltip-anchor");
    const tooltip = document.querySelector(`[data-tooltip="${tooltipId}"]`);
    const popper = createPopper(tooltipAnchor, tooltip, {
      placement: "top",
      modifiers: {
        name: "arrow",
        options: {
          // It's conceptually suboptimal to define this positioning in pixels
          // instead of in Tailwind terms, but it's the best we can do using this
          // tooltip library.
          padding: 10,
        },
      },
    });

    handleTooltipEvents("data-tooltip-on:" + tooltipId, () => {
      tooltip.setAttribute("data-tooltip-show", "true");
      popper.update();
    });
    handleTooltipEvents("data-tooltip-off:" + tooltipId, () => {
      tooltip.setAttribute("data-tooltip-show", "false");
      popper.update();
    });
    handleTooltipEvents("data-tooltip-toggle:" + tooltipId, () => {
      tooltip.setAttribute(
        "data-tooltip-show",
        tooltip.getAttribute("data-tooltip-show") === "false"
          ? "true"
          : "false",
      );
      popper.update();
    });
    handleTooltipEvents("data-tooltip-remove:" + tooltipId, () => {
      tooltip.remove();
      popper.update();
    });
  }
};
